import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

export const HelmetBlock = ({ data }) => {
  const {
    title,
    databaseId,
    seo: { title: seoTitle, metaDesc: seoDesc },
  } = data;
  const [canonical, setCanonical] = useState(null);
  const bodyProps = {};
  let noindex = false;
  const helmetTitle = seoTitle ? seoTitle : title + " | GOprestamo";

  useEffect(() => {
    setCanonical(window.location.href);
  }, []);

  switch (databaseId) {
    case 28:
    case 68:
    case 70:
    case 72:
      noindex = true;
      break;
    case 96:
      noindex = true;
      bodyProps.className = "comparador";
      bodyProps.id = "comparador";
      break;
    default:
      break;
  }

  return (
    <Helmet>
      <title>{helmetTitle}</title>
      {seoDesc && <meta name="description" content={seoDesc} />}
      <link rel="canonical" href={canonical} />
      <meta
        name="facebook-domain-verification"
        content="yloy7m9tqnkuw9b3n2nccyhify9ku8"
      />
      <meta property="og:title" content={helmetTitle} />
      <meta property="og:type" content="website" />
      {seoDesc && <meta property="og:description" content={seoDesc} />}
      <meta property="og:url" content={canonical} />
      <meta property="og:site_name" content="GOprestamo" />
      <meta name="twitter:title" content={helmetTitle} />
      {seoDesc && <meta property="twitter:description" content={seoDesc} />}
      {noindex && <meta name="robots" content="noindex, nofollow" />}
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap"
      />
      <body {...bodyProps} />
    </Helmet>
  );
};
